.top_menu {
  background: #fff;
  height: 32px;
  width: 1000px;
  /* margin: auto; */
}

.brand_text {
  float: left;
  font-size: 12px;
  font-weight: bold;
  color: #333;
  line-height: 32px;
  letter-spacing: -0.5px;
  font-family: "Noto Sans KR", sans-serif;
}

.nav_top {
  margin: 0 auto;
  width: 1000px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  letter-spacing: -1px;
}

.nav_top_none {
  list-style: none;
  display: inline;
}

.top_right {
  float: right;
  font-size: 13px;
  font-weight: bold;
  color: #333;
  line-height: 30px;
}

.panda_red {
  display: inline;
  float: right;
  font-size: 13px;
  font-weight: bold;
  color: #333;
  line-height: 30px;
  color: var(--panda-main-color);
}

.panda_red1 {
  color: var(--panda-main-color);
}

.main_hedader {
  background: #fff;
  height: 120px;
  width: 1000px;
  padding: 25px 0;
  font-family: "Noto Sans KR", sans-serif;
}

.logo {
  vertical-align: baseline;
  float: left;
  margin: 2px 20px 0 0;
}

.search {
  position: absolute;
  width: 560px;
  height: 46px;
  background: #fff;
  border: 2px solid var(--panda-main-color);

  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.5px;
  color: #000;
  padding: 0 150px 0 15px;
  border-radius: 30px;
  caret-color: var(--panda-main-color);
  outline: var(--panda-main-color);
}

.button {
  position: relative;
  background: none;
  border: none;
  float: right;
  left: -240px;
  top: 10px;
}

.header_right {
  position: relative;
  float: right;
  top: -22px;
  left: 20px;
}

.header_right_txt {
  font-size: 12px;
  margin-top: 4px;
  font-size: 12px;
  color: #333;
  display: block;
  letter-spacing: -1px;
  font-weight: normal;
}

.cnt_est {
  position: relative;
  text-align: center;
  padding: 2px 4px;
  border-radius: 10px;
  color: #fff;
  background: var(--panda-main-color);
  text-align: center;
  font-size: 12px;
  float: right;
  top: -60px;
}

.header_icon {
  position: relative;
  list-style: none;
  float: left;
  margin-left: 50px;
}

.keyword {
  position: relative;
  list-style: none;
  top: 60px;
  left: 14px;
  text-align: center;
}

.no {
  float: left;
  margin-right: 18px;
  font-size: 12px;
}

.nav {
  background: #fff;
  padding: 15px 0 30px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
}

.nav_info {
  margin: 0 auto;
  width: 1000px;
  color: #2f2f2f;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: -1px;
}

.nav_info_none {
  list-style: none;
  display: inline;
}

.top_right {
  float: right;
  margin-left: 8px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  letter-spacing: -1px;
}

.nav_info_none li {
  float: left;
  margin-right: 50px;
  cursor: pointer;
  /* display: ; */
}
.main_slide {
  width: 1100px;
  height: 100%;
  font-family: "Nanum Square", "Montserrat";
}
.main_slidebox {
  width: 770px;
  height: 475px;
  display: block !important;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.main_slide {
  width: 1100px;
  margin: 0 auto;
}

.main_banner {
  width: 1100px;
  margin: 0 auto;
  height: 475px;
}

.main_banner_lt {
  width: 70%;
  height: 100%;
  float: left;
  margin-right: 1.5px;
}

.main_banner_rt {
  width: calc(30% - 1.5px);
  height: 100%;
  float: left;
}

.main_banner_up {
  width: 100%;
  height: calc(55% - 1.5px);
}
.main_banner_down {
  width: 100%;
  height: 45%;
  margin-top: 1.5px;
  background-image: url("https://stage.cdn.pandaparts.co.kr/images/072.1_JetsonNanoOrin.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.main_banner_down a {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
}

.main_banner_down a .main_banner_square_ex span:first-child {
  font-size: 17px;
  line-height: 1.5;
}

.main_banner_down a .main_banner_square_ex span:last-child {
  font-size: 25px;
}

.main_banner_square {
  position: relative;
  width: calc(50% - 3px);
  height: calc(50% - 3px);
  margin: 1.5px;
  float: left;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  overflow: hidden;
}

.main_banner_square a {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
}

.main_banner_square_ex {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  transform: translateY(100%);
  transition: 1s;
}

.main_banner_square_ex div:last-child {
  background: #111;
  opacity: 0.45;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.main_banner_square_ex div:first-child {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.main_banner_square_ex span {
  color: #fff;
  display: block;
}

.main_banner_square_ex span:first-child {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -25%);
  line-height: 1.5;
}

.main_banner_square_ex span:nth-child(2) {
  font-size: 11px;
  bottom: 5px;
  left: 5px;
  position: absolute;
  font-weight: bold;
}

.main_banner_down .main_banner_square_ex span:nth-child(2) {
  font-size: 13px;
}

.main_banner_square_ex span:last-child {
  font-size: 15px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.main_banner_menu {
  width: 100%;
  height: 60px;
  line-height: 40px;
  background: #3c464d;
  border-top: 1px solid #ccc;
}

.main_banner_menu span {
  display: block;
  height: 28px;
}

.main_banner_center {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0 auto;
  display: flex;
}

.main_banner_center button {
  flex: 1 1 auto;
  color: #fff;
  font-size: 13.5px;
  width: 100%;
  height: 100%;
  display: inline-block;
  font-size: 11.5px;
}

.main_logo {
  width: 1100px;
  height: 220px;
  margin: 40px auto 0;
  font-family: "Nanum Square", "Montserrat";
}

.main_logo ul {
  width: 100%;
  height: 110px;
}

.main_logo ul li {
  width: 20%;
  height: 100%;
  float: left;
  box-shadow: 10px 5px 5px #ddd;
}

.main_logo ul li a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  transition: 1s;
  font-family: "Montserrat";
}

.brand_img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.main_logo ul li:first-child .brand_img {
  background-image: url(../public/images/brand/01.raspberry_pi.png);
  width: 60px;
  height: 75px;
  top: 10px;
}
.main_logo ul li:nth-child(2) .brand_img {
  background-image: url(../public/images/brand/02.arduino.png);
  width: 100px;
  height: 50px;
  top: 20px;
}
.main_logo ul li:nth-child(3) .brand_img {
  background-image: url(../public/images/brand/03.dfrobot.png);
  width: 155px;
  height: 55px;
  top: 25px;
}
.main_logo ul li:nth-child(4) .brand_img {
  background-image: url(../public/images/brand/04.sparkfun.png);
  width: 150px;
  height: 50px;
  top: 20px;
}
.main_logo ul li:nth-child(5) .brand_img {
  background-image: url(../public/images/brand/05.nvdia.png);
  width: 130px;
  height: 50px;
  top: 20px;
}
.main_logo ul li:nth-child(6) .brand_img {
  background-image: url(../public/images/brand/10.robotshop.png);
  width: 155px;
  height: 55px;
  top: 30px;
}
.main_logo ul li:nth-child(7) .brand_img {
  background-image: url(../public/images/brand/08.adafruit.png);

  width: 130px;
  height: 40px;
  top: 25px;
}
.main_logo ul li:nth-child(8) .brand_img {
  background-image: url(../public/images/brand/06.seed_studio.png);

  width: 160px;
  height: 60px;
  top: 20px;
}
.main_logo ul li:nth-child(9) .brand_img {
  background-image: url(../public/images/brand/07.particle.png);

  width: 65px;
  height: 80px;
  top: 10px;
}
.main_logo ul li:last-child .brand_img {
  background-image: url(../public/images/brand/09.espressif_.png);

  width: 50px;
  height: 80px;
  top: 10px;
}

.brand_name {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 9.5px;
  font-weight: 600;
  font-family: "Montserrat";
  text-align: center;
  width: 75%;
}

.main_new_product {
  margin-bottom: 70px;
  font-family: "Nanum Square", "Montserrat";
}
.main_new_product,
.main_distributor {
  width: 100%;
  height: 1100px;
  margin-top: 70px;
  position: relative;
  font-family: "Nanum Square", "Montserrat";
}

.main_distributor {
  margin-bottom: 100px;
  height: 250px;
}

.main_new_product_bx > button {
  width: fit-content;
  height: fit-content;
  padding: 5px 35px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* background-color: #ece2d2; */
  background-color: #3c464d;
  color: #fff;
  float: left;
  margin-right: 3px;
  font-size: 14.5px;
}

.new_pd_menu_active {
  background-color: #fbeee6 !important;
  font-weight: bold;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 15%);
  color: #3c464d !important;
}

.main_new_product_bx {
  position: absolute;
  top: -30px;
}

.main_new_product_detail,
.main_distributor_detail {
  width: 1100px;
  margin: 0 auto;
  height: 100%;
  /* background-color: #efefef; */
  background-color: #fbeee6;
  box-shadow: 10px 5px 5px #ddd;
}

.main_distributor_detail {
  height: calc(100% - 40px);
  background-color: transparent;
  box-shadow: none;
}

.main_new_product_swiper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.pd_swiper {
  width: 100%;
  height: 100%;
}

.main_new_distributor_swiper {
  width: 100%;
  height: 100% !important;
}

.main_new_distributor_swiper_slide {
}

.main_new_distributor_swiper_slide .pd_detail_img {
  box-shadow: 10px 5px 5px #ddd;
  height: 100%;
  position: absolute;
}

.swiper_slide_div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 70%;
  overflow: hidden;
  box-shadow: 10px 5px 5px #ddd;
}

.main_new_distributor_swiper_slide .pd_detail_img {
  height: ;
}

.main_distributor_detail .main_new_distributor_swiper_slide .pd_detail_img {
  height: 100%;
}

.main_new_product_swiper ul,
.main_distributor_detail > ul {
  padding: 10px 0;
  height: 100%;
  width: 90%;
  margin: 0 auto;
}

.main_new_product_swiper ul li,
.main_distributor_detail ul li {
  width: calc(25% - 20px);
  height: 23%;
  float: left;
  overflow: hidden;
  margin: 15px 10px 0 10px;
}

.main_distributor_detail ul li {
  height: 95%;
}

.main_new_product_swiper ul li:last-child {
  margin-right: 10px;
}

.main_distributor_detail ul li .pd_detail_ex img {
  filter: opacity(0.5) drop-shadow(0 0 0 #fff);
}

.main_distributor_detail ul li .pd_detail_ex span:first-child {
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translate(-5%, -50%);
}

.main_distributor_detail ul li .pd_detail_ex span:last-child {
  position: absolute;
  top: 50%;
  right: 5%;
  left: unset;
  transform: translate(5%, -50%);
}

/* 삭제예정 */

.pd_detail_img {
  width: 100%;
  height: 61%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  background-color: #fff;
}

.pd_detail_ex {
  width: 100%;
  height: 39%;
  position: relative;
  background-color: #3c464d;
  color: #fff;
  text-shadow: 2px 2px 2px rgb(0 0 0 / 15%);
}

.pd_detail_ex > span:first-child {
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 15px;
  font-weight: 600;
  width: 72%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pd_detail_ex > span:nth-child(2) {
  position: absolute;
  top: 25px;
  left: 5px;
  font-size: 11px;
  font-weight: 400;
}
.pd_detail_ex > span:nth-child(3) {
  position: absolute;
  bottom: 60px;
  left: 5px;
  font-weight: 500;
  font-size: 12.5px;
  margin-right: 5px;
}

.pd_detail_ex > span:nth-child(4) {
  font-size: 13.5px;
  position: absolute;
  bottom: 40px;
  left: 5px;
}

.pd_detail_ex > span:nth-child(4) > span {
  color: #f56565;
  font-weight: 700;
}

.pd_detail_ex > span:last-child {
  position: absolute;
  top: 2.5px;
  right: 5px;
}

.pd_detail_ex > span:last-child i {
  font-size: 25px;
}

.pd_detail_before_price {
  text-decoration: line-through;
}

.pd_detail_inventory_number {
  text-decoration: none;
}

.pd_detail_btn_other_back_order,
.pd_detail_btn_other_inventory {
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 2.5px 5px;
  border-radius: 3.5px;
  z-index: 100;
}

.pd_detail_btn_other_back_order {
  background-color: #fff;
  color: #ee3842;
}
.pd_detail_btn_other_inventory {
  background-color: orange;
}

.pd_detail_back_order_btn button,
.pd_detail_inventory_btn a {
  position: absolute;
  top: 3.5px;
  right: 5px;
  display: block;
  width: 30px;
  height: 30px;
  background: url(../public/images/cart_back_order.png);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.pd_detail_inventory_btn a {
  background: url(../public//images//agreement.png) !important;
  background-position: 50% 50% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 25px;
  height: 25px;
  filter: invert(99%) sepia(100%) saturate(0%) hue-rotate(151deg)
    brightness(102%) contrast(100%);
}

.main_ad {
  width: 1100px;
  margin: 30px auto;
  height: 120px;
  overflow: hidden;
  font-family: "Nanum Square", "Montserrat";
}

.main_ad_detail {
  width: 100%;
  height: 100%;
  background-color: #ea2f3c;
  border: 1px solid transparent;
  position: relative;
}

.main_ad_detail > div {
  position: absolute;
}

.main_ad_detail_img {
  width: 80px;
  height: 80px;
}

.main_ad_detail_ex {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: "GmarketSans";
}

.main_ad_detail_img:first-child {
  top: 50%;
  left: 10%;
  transform: translate(-10%, -50%);
}

.main_ad_detail_img:last-child {
  top: 50%;
  right: 10%;
  transform: translate(10%, -50%);
}

.main_ad_detail_ex > span {
  display: block;
  text-align: center;
  font-size: 29.5px;
  color: #ffee61;
  font-weight: bold;
  line-height: 1.5;
}

.main_ad_detail_ex > span:last-child {
  color: #fff;
}

.main_distributor ul li a div:first-child {
  transition: 1s;
}

.special {
  width: 100%;
  background: #f6f6f6;
  height: 300px;
}

.rowrow {
  width: 1000px;
  margin: 0 auto;
  padding-top: 50px;
}

.special_set {
  padding: 0 35px;
  float: left;
  width: 33%;
  text-align: center;
}

.roundbg {
  background: #fff;
  width: 110px;
  height: 110px;
  border-radius: 75px;
  margin: 0 auto;
}

.center {
  display: inline-block;
  margin-top: 25px;
}

.special_font {
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #2f2f2f;
  letter-spacing: -0.5px;
  font-family: "Noto Sans KR", sans-serif;
}
.special_subfont {
  line-height: px;
  font-size: 16px;
  color: #54585a;
  letter-spacing: -1px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
}

.container {
  width: 1000px;
  margin: 0 auto;
}

.review {
  background: #f6f6f6;
  margin-top: 20px;
  border-radius: 10px;
  min-height: 350px;
  position: relative;
  overflow: hidden;
}

.review_img {
  float: right;
}

.review_title {
  padding-top: 30px;
  padding-left: 40px;
}

.review_font {
  font-size: 24px;
  color: #333;
  font-weight: 800;
  letter-spacing: -2px;
  font-family: "Noto Sans KR", sans-serif;
  line-height: 38px;
}

.cardset {
  width: 100%;
  display: block;
  margin-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.cardbody {
  height: 150px !important;
  background: #fff;
  border-radius: 10px;
  padding: 25px;
  box-sizing: border-box;
  box-shadow: 6px 6px 20px 0 rgb(0 0 0 / 10%);
}

.card_text {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  color: #333;
  display: block;
  text-decoration: none;
  color: #333;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.author {
  bottom: 55px;
  margin-bottom: 30px;
}

.author_id {
  font-size: 16px;
  font-weight: bold;
  color: #555;
  float: left;
  letter-spacing: -1px;
  font-family: "Noto Sans KR", sans-serif;
}
.review_star {
  position: relative;
  float: right;
}

.panda_before_after {
  margin-top: 50px;
  padding-bottom: 80px;
}

.before_aftertext {
  margin-top: 50px;
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: bold;
  font-family: "Noto Sans KR", sans-serif;
}

.tablestyle {
  width: 1000px;
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
}

.tabletitle1 {
  padding: 20px 20px;
  border: 1px solid #e0e0e0;
  font-size: 16px;
  font-family: "Noto Sans KR", sans-serif;
}

.table_after {
  font-size: 16px;
  color: #fff;
  background: var(--panda-main-color);
  border-top: 10px solid var(--panda-main-color);
  border-left: 10px solid var(--panda-main-color);
  border-right: 10px solid var(--panda-main-color);
}
.tabletitle {
  border: 1px solid #e0e0e0;
  padding: 20px 20px;
  color: #555;
  line-height: 20px;
  font-family: "Noto Sans KR", sans-serif;
}

.after_text {
  line-height: 25px;
  padding: 20px 20px;
  border: 1px solid #e0e0e0;
  border-left: 10px solid var(--panda-main-color);
  border-right: 10px solid var(--panda-main-color);
}

.after_text1 {
  padding: 20px 20px;
  border: 1px solid #e0e0e0;
  border-left: 10px solid var(--panda-main-color);
  border-right: 10px solid var(--panda-main-color);
  border-bottom: 10px solid var(--panda-main-color);

  letter-spacing: -0.5px;
}

.row_footer1 {
  background: #fff;
  height: 260px;
  border-top: 1px solid #f2f2f2;
  padding: 10px 0 10px 0;
}
.contact_info {
  margin: 0 auto;
  width: 1000px;
  color: #a1a1a1;
}

.contact_menu {
  float: left;
  list-style: none;
  line-height: 30px;
  width: 25%;
}

.font_size {
  line-height: 25px;
  font-size: 14px;
  color: #666;
  letter-spacing: -1px;
}

.notice_list {
  float: right;
  line-height: 30px;
}

.h5 {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -1px;
  font-family: "Noto Sans KR", sans-serif;
  color: #54585a;
}

.li {
  line-height: 20px;
  color: #696969;
}

.text_red {
  font-weight: bold;
  color: var(--panda-main-color);
  font-family: "Noto Sans KR", sans-serif;
}

.notice_list {
  list-style: none;
  padding-bottom: 30px;
}

.ul {
  font-size: 14px;
  color: #666;
  letter-spacing: -0.5px;
  font-family: "Noto Sans KR", sans-serif;
}

.row_footer2 {
  background: #f6f6f6;
  padding: 35px 0 20px 0;
  font-size: 12px;
  color: #a1a1a1;
  letter-spacing: -1px;
  font-family: "Noto Sans KR", sans-serif;
}

.company_info {
  margin: 0 auto;
  width: 1000px;
  color: #a3a5ad;
}

.company_none {
  list-style: none;
  display: inline;
}

.info {
  float: left;
  margin-right: 30px;
  font-weight: 500;
  color: #54585a;
  font-size: 14px;
  letter-spacing: -1px;

  font-family: "Noto Sans KR", sans-serif;
}

.address {
  line-height: 20px;
  font-size: 12px;
  letter-spacing: -0.3px;
  letter-spacing: -1px;
  font-family: "Noto Sans KR", sans-serif;
}

.row_footer3 {
  font-size: 11px;
  color: #a3a5ad;
  padding: 25px 0;
  letter-spacing: -1px;
  font-family: "Noto Sans KR", sans-serif;
}

.platform_service {
  margin: 0 auto;
  width: 1000px;
  text-align: center;
  line-height: 15px;
  letter-spacing: -0.5px;
  font-family: "Noto Sans KR", sans-serif;
}

.postpaybox {
  width: 100%;
  margin: 0 auto;
}

.postpay_center {
  text-align: center;
}

.top_affiliate {
  height: 320px;
  background: #edf7ff;
  background-image: url("/images/affiliate_bg.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.tex_div span {
  font-weight: bold;
  font-family: "Noto Sans KR", sans-serif;
  color: #000;
}

.tex_div h3 {
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  font-family: "Noto Sans KR", sans-serif;
  letter-spacing: -2px;
  color: #000;
  margin-top: 10px;
}

.tex_div {
  margin: 0 auto;
  text-align: center;
  padding-top: 80px;
}

.tex_div span {
  font-size: 16px;
  font-weight: bold;
  font-family: "Noto Sans KR", sans-serif;
  color: #000;
}

.tex_center {
  font-size: 16px;
  color: #000;
  padding-top: 40px;
  font-family: "Noto Sans KR", sans-serif;
}

.title_01 {
  font-size: 28px;
  line-height: 20px;
  font-weight: bold;
  font-family: "Noto Sans KR", sans-serif;
  letter-spacing: -1px;
  color: #333;
  text-align: center;
  margin-top: 80px;
}

.title_01 p {
  text-align: center;
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  font-family: "Noto Sans KR", sans-serif;
  color: #000;
}

.info_join {
  margin: 0 auto;
  display: inline-block;
  margin: 20px 0 50px 0;
}

.info_join_set {
  float: left;
  margin-right: 100px;
}

.info_join_set:last-child {
  margin-right: 0px;
}

.us_div {
  text-align: center;
  background-color: #fafafa;
  margin: 0 auto;
  width: 100%;
}

.us_div img {
  vertical-align: middle;
}

.us_right {
  display: inline-block;
  position: relative;
  text-align: left;
  top: 50px;
}

.us_right span {
  font-size: 28px;
  line-height: 40px;
  font-weight: bold;
  font-family: "Noto Sans KR", sans-serif;
  letter-spacing: -2px;
  color: #000;
  display: inline-block;
}

.us_right Button {
  margin-top: 20px;
  display: block;
  width: 200px;
  height: 50px;
  background: #0080ff;
  border-radius: 100px;
  border: 0px;
  color: #fff;
  font-size: 16px;
  font-family: "Noto Sans KR", sans-serif;
}

/* pagination */

.pageUl {
  float: center;
  list-style: none;
  text-align: center;
  padding: 1px;
  color: #555555;
}
.pageLi {
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  padding: 5px;
  border-radius: 5px;
  width: 25px;
}

.search_list {
  height: 30px;
  width: 115%;
  display: block;
  margin-top: 5px;
  padding-right: 100px !important;
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.search_list_slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px !important;

  margin-right: 7px;
  color: #333;
  font-size: 12px;
  text-align: center;
  padding-bottom: 20px;
}

.main_distributor_detail .swiper-button-disabled {
  display: none !important;
  transition: 1s;
}

.main_distributor h3 {
  width: 1100px;
  margin: 0 auto;
  padding-bottom: 10px;
  color: #555;
}

.main_distributor h3 span {
  font-size: 20px;
  font-weight: 600;
  margin-left: 10px;
}

.pd_detail_right {
  width: 60px;
  height: 25px;
  font-size: 12px;
  background-color: rgb(62, 151, 255);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
}
.pd_detail_special {
  width: 60px;
  height: 25px;
  font-size: 12px;
  background-color: rgb(234, 47, 60);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
}

.earning_chart {
  position: relative;
  width: 100%;
  height: 100%;
}

.pd_detail_right {
  width: 60px;
  height: 25px;
  font-size: 12px;
  background-color: rgb(62, 151, 255);
  color: rgb(255, 255, 255) !important;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
}
.pd_detail_special {
  width: 60px;
  height: 25px;
  font-size: 12px;
  background-color: rgb(234, 47, 60);
  color: rgb(255, 255, 255) !important;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
}

.main_container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 100%;
  background-color: #fbeee6 !important;
  margin-bottom: 40px;
}

.main_container .main_tp {
  width: 100%;
  height: 12.5%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 0 15px;
}

.main_container .bx_indicator {
  flex: 1;

  margin: 0 1.5%;
  padding: 20px;
  position: relative;
  height: 125px;
  margin-top: 20px;
  background-color: #fff;
}

.bx_indicator h4 {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #444;
  font-size: 15px;
}

.main_container .bx_indicator p {
  position: absolute;
}

.main_container .bx_indicator p:first-of-type {
  font-size: 1.25rem;
  letter-spacing: 0.25rem;
  top: 30px;
  left: 10px;
  font-weight: bold;
}

.main_container .bx_indicator p:nth-of-type(2) {
  font-size: 13.5px;
  left: 10px;
  top: 80px;
}

.main_container .bx_indicator p:last-child {
  font-size: 13.5px;
  right: 10px;
  top: 80px;
}

.main_container .main_md {
  width: 100%;
  height: 1000px;
  position: relative;
  display: grid;
  padding-bottom: 20px;
  grid-template-columns: repeat(1, 1fr);
}

.main_container .main_lft {
  position: relative;
}

.main_container .main_lft h3,
.main_container .main_rt h3 {
  display: block;
  font-size: 20px;
  font-weight: bold;
  width: fit-content;
  height: fit-content;
  margin: 1.5% 20px 10px;
}

.main_container .main_rt h3 {
  margin: 1.5% 20px 35px;
}
.main_container .main_lft h3 {
  flex: 15;
}

.main_container .main_rt {
  position: relative;
  flex: 2;
}

.main_container .main_lft > .chart_frame,
.main_container .main_rt > .chart_frame {
  position: relative;
  width: 90%;
  height: 80%;
  margin: 20px;
  background-color: #fff;
}

.main_container .main_lft > div button,
.main_container .main_rt > div button {
  flex: 1;
  background-color: #f56565;
  color: #fff;
  padding: 8px 13px;
  margin-top: 15px;
  border-radius: 5px;
}

.main_container .main_lft > div button:first-of-type {
  margin-right: 10px;
}

.main_container .main_btm {
  width: 100%;
  height: 40%;
  position: relative;
}
.main_container .main_btm h3 {
  display: block;
  font-size: 20px;
  font-weight: bold;
  width: fit-content;
  height: fit-content;
  margin: 20px 10px;
  flex: 9;
}
.main_container .main_btm .btm_title {
  display: flex;
}

.main_container .main_btm button {
  font-size: 13.5px;
  font-weight: bold;
  margin-right: 2%;
}
.main_container .main_btm .tableWrap {
  width: 98%;
  border: 5px solid #272953;
}

.main_container .main_btm table {
  width: 98%;
  margin: 15px auto;
}

.main_container .main_btm table thead {
  font-size: 13.5px;
  background-color: #272953;
  width: 100%;
  height: 45px;
  color: #fff;
}
.main_container .main_btm table tbody {
  text-align: center;
  font-size: 13.5px;
}
.main_container .main_btm table tbody tr {
  border-top: 1px solid #ccc;
}
.main_container .main_btm table tbody tr:last-child {
  border-bottom: 1px solid #ccc;
}

.main_container .main_btm table tbody tr td {
  padding: 15px 0;
}

/* 기본 및 tablet 가로 */
@media (max-width: 1024px) {
  .search_list {
    width: 100%;
  }

  .main_banner {
    width: 100%;
    height: 480px;
  }

  .main_slide {
    width: 100%;
  }

  .main_logo {
    width: 100%;
    height: 250px;
  }

  .main_new_product_detail {
    width: 100%;
  }
  .main_slidebox {
    width: 100%;
  }

  .main_new_product_bx {
    display: flex;
    width: 100%;
  }

  .main_ad {
    width: 100%;
  }

  .main_new_product_swiper ul li {
    width: calc(27% - 30px);
    margin: 15px 5px 0 5px;
    height: 23%;
  }

  .main_ad_detail_ex > span {
    font-size: 20px;
  }

  .main_distributor_detail {
    width: 100%;
    height: calc(100% - 20px);
  }

  .main_distributor h3 {
    width: 100%;
  }
  .container {
    width: 100%;
  }
  .rowrow {
    width: 100%;
  }

  .panda_before_after h3,
  .before_aftertext {
    padding-left: 2.5%;
  }

  .tablestyle {
    width: 95%;
    margin: 0 auto;
  }
}

/* tablet 세로 */
@media (max-width: 768px) {
  .search_list {
    margin-left: 0;
    padding-right: 0 !important;
    margin-top: 10px;
    justify-content: flex-start;
  }

  .main_slide {
    height: 1000px;
  }

  .main_banner {
    height: calc(100% - 40px);
  }

  .main_banner_lt {
    width: 100%;
    height: fit-content;
  }

  .main_banner_rt {
    width: 100%;
    height: 660px;
  }

  .main_banner_square {
    width: calc(50% - 3px);
    height: 49%;
  }

  .main_banner_up {
    height: 45%;
  }

  .main_banner_down {
    height: 55%;
  }

  .main_banner_square_ex {
    transform: none;
  }

  .main_banner_square_ex div:last-child {
    background: #333;
    opacity: 0.35;
  }

  .main_banner_down a .main_banner_square_ex span:first-child {
    font-size: 20px;
  }

  .main_banner_down .main_banner_square_ex span:nth-child(2) {
    font-size: 15px;
    line-height: 1.5;
  }

  .main_slidebox {
    height: 300px;
  }

  .main_new_product {
    height: 2500px;
  }
  .main_new_product_swiper ul li {
    width: 40%;
    margin: 5px 5%;
    height: 12%;
  }

  .main_new_product_bx button {
    flex: 1;
    padding: 0;
    height: 30px;
    font-size: 13.5px;
  }

  .main_new_product_swiper ul li .pd_detail_img {
    height: 62%;
  }

  .main_new_product_swiper ul li .pd_detail_ex {
    height: 33%;
  }

  .main_ad {
    height: 250px;
  }

  .main_distributor_detail {
    height: 100%;
  }

  .main_ad_detail_ex > span {
    font-size: 19px;
    line-height: 1.75;
  }

  .main_ad_detail_img:first-child {
    left: 5%;
    transform: translate(-5%, -50%);
  }

  .main_ad_detail_img:last-child {
    right: 5%;
    transform: translate(5%, -50%);
  }

  .panda_before_after > div {
    overflow-x: scroll;
  }

  .tablestyle {
    width: 150%;
    margin-left: 2.5%;
  }

  .review {
    width: 95%;
    margin: 0 auto;
  }
  .review_img {
    width: 200px;
    height: auto;
  }

  .review_font {
    font-size: 16px;
  }

  .review_title {
    padding-top: 20px;
    padding-left: 30px;
  }

  .special {
    height: 350px;
  }
  .special_font {
    font-size: 16px;
  }

  .special_subfont {
    font-size: 13.5px;
  }

  .special_set {
    padding: 0 10px;
  }

  .main_banner_square_ex span:nth-child(2) {
    bottom: 10px;
    left: 10px;
  }

  .pd_detail_ex > span:nth-child(3) {
    bottom: 40px;
  }

  .pd_detail_ex > span:nth-child(4) {
    bottom: 10px;
  }
}

/* 모바일  */
@media (max-width: 480px) {
  .main_banner_lt {
    height: 300px;
  }

  .main_slidebox {
    height: 300px;
  }

  .brand_name {
    font-size: 10.5px;
  }

  .main_new_product_bx button {
    font-size: 10.5px;
  }

  .main_ad {
    height: 200px;
  }

  .main_ad_detail_ex > span {
    font-size: 15.5px;
    line-height: 2;
  }

  .main_ad_detail_ex > span {
    font-size: 13.5px;
  }

  .main_ad_detail_img {
    width: 70px;
    height: 70px;
  }

  .main_new_product {
    height: 2200px;
  }

  .main_new_product_swiper ul li {
    width: 45%;
    margin: 5px 2%;
  }

  .main_new_product_swiper ul li .pd_detail_ex {
    height: 51%;
  }

  .main_new_product_swiper ul li .pd_detail_img {
    height: 49%;
  }

  .main_new_product_swiper ul {
    padding: 20px 0;
  }

  .special {
    height: 800px;
  }

  .rowrow {
    height: 100%;
    padding-top: 25px;
  }

  .special_set {
    width: 95%;
    margin: 20px auto;
  }

  .pd_detail_ex > span:nth-child(3) {
    bottom: 60px;
  }

  .pd_detail_ex > span:nth-child(4) {
    bottom: 40px;
  }

  .special_subfont {
    font-size: 14.5px;
  }

  .special_font {
    font-size: 18.5px;
  }

  .review_title {
    padding-left: 10px;
    font-size: 15px;
  }

  .review_img {
    width: 100%;
    display: block;
    height: auto;
  }

  .review_img img {
    width: 150px;
    float: right;
  }
}

/* 모바일(구) */
@media (max-width: 370px) {
  .main_banner_down a .main_banner_square_ex span:first-child {
    font-size: 20px;
  }

  .main_banner_down .main_banner_square_ex span:nth-child(2) {
    font-size: 15px;
    line-height: 1.5;
  }

  .main_banner_square_ex span:first-child {
    font-size: 15px;
  }

  .main_banner_square_ex span:nth-child(2) {
    font-size: 14px;
  }
}
